import { app } from '../resources/gcp-config';
import {
  getFirestore,
  doc,
  getDoc,
  onSnapshot,
  collection,
  getDocs,
} from 'firebase/firestore';

const db = getFirestore(app);

export const fetchAllDeliveriesData = (callback) => {
  const q = collection(db, 'deliveries');
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const deliveries = querySnapshot.docs.map((doc) => {
      const deliveryData = doc.data();

      let updatedTimestamp = '';

      if (deliveryData.updatedAt?.toDate) {
        // ✅ If it's a Firestore Timestamp, convert it properly
        updatedTimestamp = deliveryData.updatedAt.toDate().toLocaleString();
      } else if (deliveryData.updatedAt?.seconds) {
        // ✅ If it's a timestamp object (e.g., { seconds: 1708455600 }), convert it
        updatedTimestamp = new Date(deliveryData.updatedAt.seconds * 1000).toLocaleString();
      } else if (typeof deliveryData.updatedAt === 'string') {
        // ✅ If it's already a string, keep it as is
        updatedTimestamp = deliveryData.updatedAt;
      } else {
        // ✅ Fallback: Use the current date/time if `updatedAt` is missing
        updatedTimestamp = new Date().toLocaleString();
      }

      return {
        ...deliveryData,
        docId: doc.id,
        updatedAt: updatedTimestamp, // ✅ Always a valid string now
      };
    });
    callback(deliveries); // Call the callback function with the result
  });

  // Return the unsubscribe function
  return unsubscribe;
};

export const fetchSingleDeliveryData = async (docId) => {
  const docRef = doc(db, 'deliveries', docId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return { ...docSnap.data(), docId: docSnap.id };
  } else {
    console.error(new Error('No such document!'));
    return null;
  }
};

export const fetchSingleDeliveryDataLive = (docId, callback) => {
  const docRef = doc(db, 'deliveries', docId);

  const unsubscribe = onSnapshot(docRef, (docSnap) => {
    if (docSnap.exists()) {
      callback({ ...docSnap.data(), docId: docSnap.id });
    } else {
      console.error(new Error('No such document!'));
      callback(null);
    }
  });

  return unsubscribe;
};

